import React from 'react';

interface DoorIconProps {
  style?: React.CSSProperties; // style プロパティの型を指定
}

const DoorIcon: React.FC<DoorIconProps> = ({ style }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-door-open-fill" viewBox="0 0 16 16" style={style}>
    <path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
  </svg>
);

export default DoorIcon;
