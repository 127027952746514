import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardFooter from './footer/dashboardFooter';
import HomeFooter from './footer/homeFooter';
import DashboardHeader from './header/dashboardHeader';
import HomeHeader from './header/homeHeader';

type LayoutProps = {
  children: ReactNode;
};

function Layout({ children }: LayoutProps) {
  const location = useLocation();

  const isDashboard = /\/(dashboard|notify|profile|settings|help)/.test(location.pathname);

  return (
    <div>
      {isDashboard ? <DashboardHeader /> : <HomeHeader />}
      <main>{children}</main>
      {isDashboard ? <DashboardFooter /> : <HomeFooter />}
    </div>
  );
}

export default Layout;
