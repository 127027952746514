import React, { Component } from 'react';
class DashboardFooter extends Component {
  boarder = {
    display: 'flex',
    background: 'blue',
    padding: '5px 0 5px 5px',
    fontSize: '20px',
  };
  render() {
    const version = process.env.REACT_APP_VERSION || '0.0.1'; // バックアップのためのデフォルト値
    const year = new Date().getFullYear();
    return (
      <footer>
        <div className="logo">
          <h6>
            Copyright © 2022-{year} Sharemore All Rights Reserved. <br></br>version: {version}
          </h6>
        </div>
      </footer>
    );
  }
}

export default DashboardFooter;
