import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const LoginButtonStyle = {
  marginLeft: 'auto', // 左側のマージンを自動で調整して右寄せ
  display: 'flex', // Flexbox を使用して右寄せにする
  color: 'white',
  textDecoration: 'none',
};

const logoStyle = {
  marginRight: 'auto', // 右側のマージンを自動で調整して右寄せ
  display: 'flex', // Flexbox を使用して右寄せにする
  color: 'black',
  textDecoration: 'none',
};

class HomeHeader extends Component {
  render() {
    return (
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        <Link to={`/`} style={logoStyle}>
          <h3>sharemore</h3>
        </Link>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link className="nav-link home" aria-current="page" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to={`/features/`}>
              Features
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/">
              Pricing
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/">
              FAQs
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/">
              About
            </Link>
          </li>
        </ul>
        <button type="button" className="btn btn-primary">
          <Link to={`/login/`} style={LoginButtonStyle}>
            Login
          </Link>
        </button>
      </header>
    );
  }
}

export default HomeHeader;
