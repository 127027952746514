import { ColumnDef, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, SortDirection, useReactTable } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from 'react-icons/ti';
import '../../css/button.css';
import '../../css/log.css';
type logConfig = {
  processName: string;
  type: string;
  message?: string;
  timestamp?: string;
  username?: string;
  inputValue?: string;
  query?: string;
  result?: string;
};

const getSortIcon = (sortDirection: false | SortDirection): JSX.Element => {
  switch (sortDirection) {
    case 'asc':
      return <TiArrowSortedUp />;
    case 'desc':
      return <TiArrowSortedDown />;
    default:
      return <TiArrowUnsorted />;
  }
};

const tableColumns: ColumnDef<any>[] = [
  {
    header: ({ column }) => {
      return (
        <div style={{ flex: 'auto', alignItems: 'center', cursor: 'pointer' }} onClick={column.getToggleSortingHandler()}>
          Timestamp{getSortIcon(column.getIsSorted())}
        </div>
      );
    },

    accessorKey: 'timestamp',
  },
  {
    header: ({ column }) => {
      return (
        <div style={{ flex: 'auto', alignItems: 'center', cursor: 'pointer' }} onClick={column.getToggleSortingHandler()}>
          ProcessName{getSortIcon(column.getIsSorted())}
        </div>
      );
    },
    accessorKey: 'processName',
  },
  {
    header: 'Type',
    accessorKey: 'type',
  },
  {
    header: 'UserId',
    accessorKey: 'userid',
  },
  {
    header: 'InputValue',
    accessorKey: 'inputValue',
  },
  {
    header: 'Query',
    accessorKey: 'query',
  },
  {
    header: 'Result',
    accessorKey: 'result',
  },
  {
    header: 'Message',
    accessorKey: 'message',
  },
];

const Log = () => {
  const [responseData, setResponseData] = useState<logConfig[]>([]);
  const [error, setError] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const memoizedData = useMemo(() => {
    responseData.forEach((data, index) => {
      if (!data) return;
      if (data.result) {
        responseData[index] = { ...data, result: JSON.stringify(data.result) };
      }
    });
    return responseData;
  }, [responseData]);
  const memoizedColumns = useMemo(() => tableColumns, []);

  const table = useReactTable({
    data: memoizedData,
    columns: memoizedColumns,
    initialState: {
      sorting: [{ id: 'timestamp', desc: true }],
    },
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
  });

  const makeRequest = () => {
    // HTTP GETリクエストを送信するためのURL
    const url = 'https://verificationenvironment.itechsearchengine.com/logging';

    // リクエストを送信
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // レスポンスデータをセット
        setResponseData(data);
      })
      .catch((error) => {
        // エラーメッセージをセット
        setError(error.message);
      });
  };

  const apiStyles = {
    padding: '1rem 1rem',
    borderRadius: '100vh',
    backgroundImage: '-webkit-gradient(linear, left top, right top, from(#fa709a), to(#fee140)), -webkit-linear-gradient(left, #fa709a 0%, #fee140 100%), linear-gradient(to right, #fa709a 0%, #fee140 100%)',
  };

  const backendStyles = {
    padding: '1rem 1rem',
    borderRadius: '100vh',
    backgroundImage: '-webkit-gradient(linear, left top, right top, from(#209cff), to(#68e0cf)), -webkit-linear-gradient(left, #209cff 0%, #68e0cf 100%), linear-gradient(to right, #209cff 0%, #68e0cf 100%)',
  };

  const loggingStyles = {
    padding: '1rem 1rem',
    borderRadius: '100vh',
    backgroundImage: '-webkit-gradient(linear, right top, left top, from(#9be15d), to(#00e3ae)), -webkit-linear-gradient(right, #9be15d 0%, #00e3ae 100%), linear-gradient(to left, #9be15d 0%, #00e3ae 100%)',
  };

  return (
    <div className="log-page">
      <h1>アクセスログページ</h1>

      <main>
        <button className="button_solid010" onClick={makeRequest}>
          Make Request
        </button>
        {error && <p>Error: {error}</p>}
        <div className="search_bar">
          <input id="text2" type="text" placeholder="Filter all..." value={(table.getState().globalFilter as string) ?? ''} onChange={(e) => table.setGlobalFilter(e.target.value)} />
        </div>

        <div>
          <button className="button019" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
            {'FIRST'}
          </button>
          <button className="button019" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
            {'PREV'}
          </button>
          <button className="button019" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            {'NEXT'}
          </button>
          <button className="button019" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
            {'LAST'}
          </button>
        </div>
        <div className="custom-select">
          <select
            style={{ margin: '10px' }}
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[1000, 5000, 10000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div>{table.getRowModel().rows.length} Rows</div>
        <table className="p4">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    const value = cell.getValue();
                    let styles;
                    if (value === 'sharemore_backend') {
                      styles = backendStyles;
                    } else if (value === 'sharemore_api') {
                      styles = apiStyles;
                    } else if (value === 'sharemore_logging') {
                      styles = loggingStyles;
                    } else {
                      //
                    }
                    return (
                      <td key={cell.id} style={styles}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div style={{ margin: '5px' }}>
          <span>Page</span>
          <strong>
            {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </strong>
        </div>
      </main>
    </div>
  );
};

export default Log;
