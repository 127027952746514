import React from 'react';

interface BellIconProps {
  style?: React.CSSProperties; // style プロパティの型を指定
  width?: number; // width プロパティの追加
  height?: number; // height プロパティの追加
}
const BellIcon: React.FC<BellIconProps> = ({ style, width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className="bi bi-bell-fill" viewBox="0 0 16 16" style={style}>
    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
  </svg>
);

export default BellIcon;
