import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../css/App.css';
import Layout from './components/layout';
import Dashboard from './page/dashboard';
import NotFound from './page/error/404-NotFound';
import Features from './page/features';
import Help from './page/help';
import Home from './page/home';
import Log from './page/log';
import Login from './page/login';
import Logout from './page/logout';
import Nofity from './page/notify';
import Profile from './page/profile';
import Settings from './page/settings';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path={`/`} element={<Home />} />
            <Route path={`/login/`} element={<Login />} />
            <Route path={`/log/`} element={<Log />} />
            <Route path={`/features/`} element={<Features />} />
            <Route path={`/dashboard/`} element={<Dashboard />} />
            <Route path={`/notify/`} element={<Nofity />} />
            <Route path={`/profile/`} element={<Profile />} />
            <Route path={`/settings/`} element={<Settings />} />
            <Route path={`/help/`} element={<Help />} />
            <Route path={`/logout/`} element={<Logout />} />
            <Route path={`*`} element={<NotFound />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    );
  }
}

export default App;
