import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UserAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    fetch('/backend/dashboard', { credentials: 'include' })
      .then((response: any) => {
        if (response.status === 401) {
          navigate('/login');
        }
        console.log('Authenticated', response, 'response.redirectUrl:', response.redirectUrl);
        //window.location.href = response.redirectUrl;
      })
      .catch(() => navigate('/login'));
  }, [navigate]);
};

export default UserAuth;
