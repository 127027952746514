import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface RefreshIconProps {
  style?: React.CSSProperties;
  onRefresh?: () => void; // 別のクラスの関数を受け取る
}

const RefreshIcon: React.FC<RefreshIconProps> = ({ style, onRefresh }) => {
  const [tooltipText, setTooltipText] = useState('Refresh the data');
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleClick = () => {
    if (!isRefreshing) {
      // 渡されたリフレッシュ関数を実行
      if (onRefresh) {
        onRefresh();
      }

      setTooltipText('The update was successful');
      setIsRefreshing(true);

      // 一定時間後に元のテキストに戻す（例: 3秒後）
      setTimeout(() => {
        setTooltipText('Refresh the data');
        setIsRefreshing(false); // 再度クリックできるようにする
      }, 3000);
    }
  };

  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-bottom">{tooltipText}</Tooltip>}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        className="bi bi-arrow-clockwise"
        viewBox="0 0 16 16"
        style={style}
        onClick={handleClick} // アイコンをクリックしたときにテキストを変更
        role="button"
      >
        <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
      </svg>
    </OverlayTrigger>
  );
};

export default RefreshIcon;
