import React from 'react';
import '../../css/list.css'; // ボタンに関するCSSファイル

const Features = () => {
  return (
    <>
      <h3>next features一覧</h3>
      <ul className="list">
        <li>Home画面</li>
        ・画面の見直し.説明と使用中の画面を挿絵としていれる <br></br>
        <li>ログイン認証</li>
        ・ログインフォームの見直し <br></br>
        ・ログインのセキュリティの見直し <br></br>
        <li>データの更新機能</li>
        ・データの取得 <br></br>
        ・データの更新 <br></br>
        ・データの削除 <br></br>
        <li>backup機能</li>
        ・export機能 <br></br>
        ・import機能 <br></br>
        <li>定期入力機能</li>
        ・指定した日に毎月入力する機能 <br></br>
        ・指定した曜日に毎週入力する機能 <br></br>
        <li>SNS共有機能</li>
        ・ブログやSNSに共有できる機能を追加する <br></br>
      </ul>
    </>
  );
};

export default Features;
