import React from 'react';

const Logout = () => {
  //const handleLogout = () => {
  //  //localStorage.removeItem('token');
  //};
  return (
    <div>
      <h2>ログアウト</h2>
    </div>
  );
};

export default Logout;
