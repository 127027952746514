import React, { Component, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { Link } from 'react-router-dom';
import BellIcon from '../icons/bellIcon';
import DoorIcon from '../icons/doorIcon';
import GearIcon from '../icons/gearIcon';
import PersonIcon from '../icons/personIcon';
import QuestionIcon from '../icons/questionIcon';

const dropdownButtonStyle = {
  marginLeft: 'auto', // 左側のマージンを自動で調整して右寄せ
  display: 'flex', // Flexbox を使用して右寄せにする
};

const logoStyle = {
  marginRight: 'auto', // 右側のマージンを自動で調整して右寄せ
  display: 'flex', // Flexbox を使用して右寄せにする
  color: 'black',
  textDecoration: 'none',
};

class DashboardHeader extends Component {
  handleClick = () => {
    console.log('Link was clicked!');
  };
  render() {
    return (
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        <Link to={`/dashboard/`} style={logoStyle}>
          <h3>sharemore</h3>
        </Link>
        <Notify />
        <Link to="/profile/" style={{ marginRight: '10px' }}>
          <PersonIcon style={{ width: '30', height: '30' }} />
        </Link>
        <div className="dropdown">
          <DropdownButton id="dropdown-basic-button" title="username" style={dropdownButtonStyle}>
            <Dropdown.Item as={Link} to="/profile/">
              <PersonIcon style={{ marginRight: '8px', width: '24', height: '24' }} />
              profile
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/settings/">
              <GearIcon style={{ marginRight: '8px' }} />
              settings
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/help/">
              <QuestionIcon style={{ marginRight: '8px' }} />
              help
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/logout/">
              <DoorIcon style={{ marginRight: '8px' }} />
              logout
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </header>
    );
  }
}

function Notify() {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClick = () => {
    setShow(!show);
  };

  // Overlay 内のクリックを無視するためのハンドラ
  const handlePopoverClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div ref={ref} onClick={handleClick}>
      <BellIcon style={{ cursor: 'pointer', marginRight: '10px', width: '22px', height: '22px' }} />

      {ref.current && ( // ref.current が存在する場合のみ Overlay をレンダリング
        <Overlay show={show} target={ref.current} placement="bottom" containerPadding={20}>
          <Popover id="popover-contained" onClick={handlePopoverClick}>
            <Popover.Header as="h3">notify</Popover.Header>
            <Popover.Body>
              <strong>No information</strong>
            </Popover.Body>
          </Popover>
        </Overlay>
      )}
    </div>
  );
}

export default DashboardHeader;
