import React, { useState } from 'react';
import '../../css/button.css';
import '../../css/login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginMessage, setLoginMessage] = useState('');

  const handleLogin = (e: any) => {
    e.preventDefault();
    setLoginMessage(''); // Clear previous messages
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          setLoginMessage('Login successful');
          const response = JSON.parse(xhr.responseText);
          window.location.href = response.redirectUrl;
        } else {
          setLoginMessage('Login failed');
        }
      }
    };

    xhr.open('POST', '/backend/', true);
    xhr.setRequestHeader('Content-Type', 'application/json');

    const data = JSON.stringify({
      username: username,
      password: password,
    });
    xhr.send(data);
  };

  return (
    <div className="login-page">
      <div className="form-wrapper">
        <h2>Sign In</h2>
        <form>
          <div className="form-item">
            <label htmlFor="userid"></label>
            <input type="userid" name="userid" placeholder="Enter your user id" value={username} onChange={(e) => setUsername(e.target.value)}></input>
          </div>
          <div className="form-item">
            <label htmlFor="password"></label>
            <input type="password" name="password" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
          </div>
          <div className="button-panel">
            <input type="submit" onClick={handleLogin} className="button" title="Sign In" value="Sign In"></input>
          </div>
        </form>
        {loginMessage && <p>{loginMessage}</p>}
      </div>
    </div>
  );
};

export default Login;
