import update from 'immutability-helper';
import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { DndProvider, DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import RefreshIcon from '../components/icons/refreshIcon';
import UserAuth from './userauth';

// Define the card type
type CardData = {
  id: number;
  title: string;
  text: string;
  displayPlace: 'main' | 'side';
};

// Props for the DraggableCard component
interface DraggableCardProps {
  displayPlace: 'main' | 'side';
  card: CardData;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  removeCard: (index: number) => void;
}

// Draggable card component
function DraggableCard({ displayPlace: place, card, index, moveCard, removeCard }: DraggableCardProps) {
  const ref = React.useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  const [, drop] = useDrop({
    accept: 'CARD',
    hover(item: { index: number }, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      if (clientOffset?.y === undefined) {
        return;
      }
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      if (dragIndex < hoverIndex && hoverClientY! < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY! > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: { index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  if (!card) {
    console.log('Card is undefined at index:', index);
    return null;
  }

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Accordion activeKey={isOpen && place !== 'side' ? String(card.id) : null} defaultActiveKey="0">
        <Accordion.Item eventKey={String(card.id)}>
          <Card style={{ width: '18rem', marginBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <span
                onClick={place !== 'side' ? handleToggle : undefined}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  position: 'relative',
                  cursor: place !== 'side' ? 'pointer' : 'default',
                }}
              >
                {card.title}
              </span>

              {place !== 'side' && (
                <Button size="sm" onClick={handleToggle}>
                  {isOpen ? '▲' : '▼'}
                </Button>
              )}

              {place !== 'side' && (
                <DropdownButton id={`dropdown-button-${card.id}`} variant="outline-secondary" size="sm" title="⚙️" align="end" onClick={(e) => e.stopPropagation()}>
                  <Dropdown.Item href="#/action-1">Action 1</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Action 2</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Action 3</Dropdown.Item>
                </DropdownButton>
              )}

              <Button size="sm" onClick={() => removeCard(index)}>
                {place === 'side' ? '+' : '×'}
              </Button>
            </div>

            <Accordion.Collapse eventKey={String(card.id)}>
              <Card.Body>
                <Card.Text>{card.text}</Card.Text>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

interface InformationCardProps {
  place: 'main' | 'side';
  cards: CardData[];
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  removeCard: (index: number) => void;
  refreshData?: () => void;
}

// Main component with all features
const InformationCard: React.FC<InformationCardProps> = ({ place, cards, moveCard, removeCard, refreshData }) => {
  return (
    <div>
      {place === 'side' && (
        <div style={{ backgroundColor: 'lightgray', width: '300px', padding: '10px', marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h3 style={{ marginRight: '10px' }}>サイド情報</h3>
            <RefreshIcon onRefresh={refreshData} style={{ cursor: 'pointer', width: '22px', height: '22px' }} />
          </div>
          <p>サイドに表示する情報です</p>
          <DndProvider backend={HTML5Backend}>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {cards.map((card, index) => (card !== undefined ? <DraggableCard displayPlace={place} key={card.id} index={index} card={card} moveCard={moveCard} removeCard={removeCard} /> : null))}
            </div>
          </DndProvider>
        </div>
      )}

      {place === 'main' && (
        <DndProvider backend={HTML5Backend}>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {cards.map((card, index) => (card !== undefined ? <DraggableCard displayPlace={place} key={card.id} index={index} card={card} moveCard={moveCard} removeCard={removeCard} /> : null))}
          </div>
        </DndProvider>
      )}
    </div>
  );
};

const Dashboard = () => {
  UserAuth();
  const [cardData, setCardData] = useState<CardData[]>([
    { id: 1, title: '自分の情報', text: '', displayPlace: 'main' },
    { id: 2, title: '共有グループの情報', text: '', displayPlace: 'main' },
    { id: 3, title: '今月の収支', text: '', displayPlace: 'main' },
    { id: 4, title: '今年の収支', text: '', displayPlace: 'main' },
    { id: 5, title: 'アクセスログ', text: '', displayPlace: 'main' },
    { id: 6, title: 'display sample', text: '', displayPlace: 'main' },
    { id: 7, title: 'display sample2', text: '', displayPlace: 'side' },
  ]);

  const [mainCards, setMainCards] = useState<CardData[]>([]);
  const [sideCards, setSideCards] = useState<CardData[]>([]);

  useEffect(() => {
    setMainCards(cardData.filter((card) => card.displayPlace === 'main'));
    setSideCards(cardData.filter((card) => card.displayPlace === 'side'));
  }, [cardData]);

  const moveCard = (place: 'main' | 'side', dragIndex: number, hoverIndex: number) => {
    const cards = place === 'main' ? mainCards : sideCards;
    const setCards = place === 'main' ? setMainCards : setSideCards;
    const dragCard = cards[dragIndex];
    setCards(
      update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      })
    );
  };

  const removeCard = (place: 'main' | 'side', index: number) => {
    const setCards = place === 'main' ? setMainCards : setSideCards;
    const sideEffectSetCards = place === 'main' ? setSideCards : setMainCards;

    const cards = place === 'main' ? mainCards : sideCards;
    const updatedCards = cards.filter((_, i) => i !== index);
    setCards(updatedCards);

    const sideEffectCards = place !== 'main' ? mainCards : sideCards;
    const updatedSideEffectCards = cards.filter((_, i) => i === index);
    sideEffectSetCards([...sideEffectCards, ...updatedSideEffectCards]);
  };

  const [, setError] = useState(null);

  const refreshData = useCallback(() => {
    console.log('refreshData');
    // HTTP GETリクエストを送信するためのURL
    const url = 'https://verificationenvironment.itechsearchengine.com/backend/';

    // リクエストを送信
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const updatedCardData = cardData.map((card, index) => ({
          ...card,
          text: data.result,
        }));
        console.log('Updated card data:', updatedCardData, data);
        setCardData(updatedCardData);
      })
      .catch((error) => {
        // エラーメッセージをセット
        console.log('Error:', error);
        setError(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('useEffect refreshData');
    refreshData(); // 初回レンダリング時に refreshData を呼び出す
  }, [refreshData]);

  return (
    <div style={{ display: 'flex' }}>
      {/* Side Cards */}

      {/* Main Cards */}
      <div style={{ flex: 1 }}>
        <InformationCard place="main" cards={mainCards} moveCard={(dragIndex, hoverIndex) => moveCard('main', dragIndex, hoverIndex)} removeCard={(index) => removeCard('main', index)} />
      </div>

      {/* Side Cards */}

      <div style={{ width: '300px' }}>
        <InformationCard place="side" cards={sideCards} moveCard={(dragIndex, hoverIndex) => moveCard('side', dragIndex, hoverIndex)} removeCard={(index) => removeCard('side', index)} refreshData={refreshData} />
      </div>
    </div>
  );
};

export type DashboardHandle = {
  refreshData: () => void;
};
export default Dashboard;
