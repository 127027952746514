import React from 'react';

const Help = () => {
  return (
    <div>
      <h2>help</h2>
    </div>
  );
};

export default Help;
