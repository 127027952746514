import background from '../../images/background/pattern_0001_0001.svg';

import React from 'react';
import '../../css/button.css'; // ボタンに関するCSSファイル

const Home = () => {
  return (
    <>
      <div style={{ backgroundImage: `url(${background})` }}>
        <div></div>
      </div>
    </>
  );
};

export default Home;
